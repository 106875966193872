import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from './firebaseConfig'; // Ajuste para importar solo `db`

const WaitingRoom = () => {
  const { eventId } = useParams(); // Obtén el ID del evento desde la URL
  const [quizStarted, setQuizStarted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = db.collection('eventos').doc(eventId)
      .onSnapshot(doc => {
        if (doc.exists && doc.data().quizStarted) {
          setQuizStarted(true);
          navigate(`/quiz/${eventId}`); // Redirige al cuestionario si ha comenzado
        }
      });

    return () => unsubscribe();
  }, [eventId, navigate]);

  return (
    <div>
      {!quizStarted ? (
        <>
          <h2>Esperando a que el organizador inicie el cuestionario...</h2>
          <p>Por favor, espera.</p>
        </>
      ) : (
        <p>Redirigiendo al cuestionario...</p>
      )}
    </div>
  );
};

export default WaitingRoom;
