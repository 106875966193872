import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './EventPage.css'; // Asegúrate de tener el CSS correcto
import { db } from './firebaseConfig'; // Asegúrate de tener configurado Firebase
import { doc, updateDoc, arrayUnion } from 'firebase/firestore'; // Usar métodos correctos del SDK de Firebase
import { useNavigate } from 'react-router-dom';

function EventPage() {
  const { nombre_evento } = useParams();
  const [preguntas, setPreguntas] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentScore, setCurrentScore] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null); // Usuario que responderá las preguntas
  const navigate = useNavigate();

  // Obtener el usuario desde localStorage
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    const fetchPreguntas = async () => {
      try {
        const response = await fetch(`https://pubtomatch.com/api/eventos/${nombre_evento}/preguntas`);
        if (!response.ok) {
          throw new Error('Error al obtener las preguntas');
        }
        const data = await response.json();
        console.log('Preguntas obtenidas:', data.preguntas);  
        setPreguntas(data.preguntas || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (nombre_evento) {
      fetchPreguntas();
    }
  }, [nombre_evento]);

  useEffect(() => {
    setCurrentScore(answers[currentQuestionIndex] || null);
  }, [currentQuestionIndex, answers]);

  const handleScoreChange = (score) => {
    setCurrentScore(score);
  };

  const handleNextQuestion = () => {
    if (currentScore !== null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = currentScore;
      setAnswers(newAnswers);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleFinish = async () => {
    if (user && user.userID) {
      try {
        // Referencia al documento del usuario
        const userDocRef = doc(db, 'usuarios', user.userID);

        // Actualiza el documento con las respuestas y añade el evento a la lista de eventos
        await updateDoc(userDocRef, {
          respuestas: answers, // Guardar las respuestas del usuario
          participacion_eventos: arrayUnion(nombre_evento) // Añadir el evento a la lista de eventos
        });

        alert('¡Gracias por participar! Tus respuestas han sido guardadas.');
      } catch (error) {
        console.error('Error al guardar las respuestas:', error);
        alert('Hubo un error al guardar tus respuestas.');
      }
    }
  };

  const handleFinishAndMatch = async () => {
    try {
      // Finalizar y guardar las respuestas
      await handleFinish();

      // Llamar a la API para emparejar usuarios
      const response = await fetch(`https://pubtomatch.com/api/eventos/${nombre_evento}/match`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ eventPassword: localStorage.getItem('eventPassword') })
      });

      const data = await response.json();
      console.log('Emparejamientos obtenidos:', data.matches);
      alert('Emparejamientos realizados con éxito. Ver la consola para detalles.');
    } catch (error) {
      console.error('Error al emparejar usuarios:', error);
      alert('Hubo un error al realizar los emparejamientos.');
    }
  };

  if (loading) return <p className="loading">Cargando preguntas...</p>;
  if (error) return <p className="error">{error}</p>;

  const currentQuestion = preguntas[currentQuestionIndex];

  if (!currentQuestion) {
    return (
      <div className="completed-section">
        <h2>Has respondido todas las preguntas. ¡Gracias por participar!</h2>
        <ul>
          {answers.map((answer, index) => (
            <li key={index}>
              Pregunta {index + 1}: {preguntas[index]} - Puntuación: {answer}
            </li>
          ))}
        </ul>
        <button onClick={handleFinishAndMatch} className="finish-btn">
          Finalizar y Guardar
        </button>
      </div>
    );
  }

  return (
    <div className="event-page-container">
      {/* Navigation Bar */}
      <nav className="nav-bar-loginpage">
        <h2 className="logo" onClick={() => navigate('/')}>PubToMatch</h2>
      </nav>
      <h2 className="event-title">{nombre_evento}</h2>
      <div className="question-section">
        <p className="current-question">{currentQuestion}</p>
        <div className="score-buttons">
          {Array.from({ length: 10 }, (_, i) => i + 1).map((score) => (
            <button
              key={score}
              onClick={() => handleScoreChange(score)}
              className={`score-btn ${currentScore === score ? 'selected' : ''}`}
            >
              {score}
            </button>
          ))}
        </div>
        <div className="nav-buttons">
          {currentQuestionIndex > 0 && (
            <button onClick={handlePreviousQuestion} className="previous-btn">
              Anterior
            </button>
          )}
          <button onClick={handleNextQuestion} disabled={currentScore === null} className="next-btn-next">
            Siguiente
          </button>
        </div>
      </div>
    </div>
  );
}

export default EventPage;
