// firebaseConfig.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCfd_LuPn3k6KwsCbZ3zgu2RMxNUWFUvKw",
  authDomain: "pubtomatch-21d75.firebaseapp.com",
  projectId: "pubtomatch-21d75",
  storageBucket: "pubtomatch-21d75.appspot.com",
  messagingSenderId: "57079211044",
  appId: "1:57079211044:web:0816bae6b5a4b240ad24b1"
};

// Inicializa Firebase solo si no ha sido inicializado previamente
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Exporta Firestore para poder usarlo en otros archivos
const db = firebase.firestore();

export { firebase, db };
