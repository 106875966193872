import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import FirstForm from './FirstForm';
import QuestionsSelection from './QuestionsSelection';
import PricePage from './PricePage';
import LoginPage from './LoginPage';
import EventPage from './EventPage';
import LoginForm from './LoginForm';
import AdminPanel from './AdminPanel';
import WaitingRoom from './WaitingRoom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/first-form" element={<FirstForm />} />
        <Route path="/questions-selection" element={<QuestionsSelection />} />
        <Route path="/price" element={<PricePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/loginform/:nombre_evento" element={<LoginForm />} />
        <Route path="/event/:nombre_evento" element={<EventPage />} />
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/waiting-room/:eventId" element={<WaitingRoom />} />
      </Routes>
    </Router>
  );
}

export default App;
