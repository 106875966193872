import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { db } from './firebaseConfig';  // Firebase configurado
import { collection, addDoc } from 'firebase/firestore';
import WaitingRoom from './WaitingRoom';

function LoginPage() {
  const [step, setStep] = useState(1); // Controla los pasos del formulario (1: validación evento, 2: registro usuario)
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [photo, setPhoto] = useState(null);
  const [gender, setGender] = useState('chico');
  const [orientation, setOrientation] = useState('heterosexual');
  const [eventPassword, setEventPassword] = useState('');  // Contraseña del evento
  const [eventName, setEventName] = useState('');  // Nombre del evento
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [isWaiting, setIsWaiting] = useState(false);
  const [eventId, setEventId] = useState(null); // Mantener el ID del evento después del registro


  // Función para validar el evento antes de mostrar el formulario de registro
  const validateEvent = async () => {
    try {
      const response = await fetch(`https://pubtomatch.com/api/eventos/${eventName}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: eventPassword }),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.message === 'Acceso concedido') {
          setStep(2); // Si el evento es válido, pasar al siguiente paso
        } else {
          setErrorMessage(data.message || 'Nombre del evento o contraseña incorrectos.');
        }
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'Nombre del evento o contraseña incorrectos.');
      }
    } catch (error) {
      console.error('Error al validar el evento:', error);
      setErrorMessage('Error al intentar validar el evento.');
    }
  };
  

  const handleSubmit = async () => {
    if (age < 18 || age > 99) {
      setErrorMessage('La edad debe estar entre 18 y 99 años.');
      return;
    }
    if (!name || !age || !photo) {
      setErrorMessage('Por favor, rellena todos los campos.');
      return;
    }

    const userData = {
      nombre_usuario: name,
      edad: parseInt(age),
      genero: gender,
      orientacion_sexual: orientation,
      photo: URL.createObjectURL(photo)
    };

    try {
      const userRef = await addDoc(collection(db, 'usuarios'), userData);
      const eventRef = collection(db, 'eventos', eventName, 'participants');
      await addDoc(eventRef, { ...userData, userId: userRef.id });

      setEventId(eventName); // Guardar el nombre del evento
      setIsWaiting(true); // Activar la pantalla de espera
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      setErrorMessage('Hubo un error al registrar el usuario.');
    }
  };
  

  const handlePhotoChange = (e) => {
    const selectedPhoto = e.target.files[0];
    setPhoto(selectedPhoto);
  };

  return (
    <div className="login-form-container">
      {isWaiting ? (
        <WaitingRoom eventId={eventId} /> // Muestra la sala de espera después del registro
      ) : (
        <>
          {step === 1 ? (
            <>
              {/* Paso 1: Validar evento */}
              <h2 className="login-title">Acceder al Evento</h2>
              <div className="form-group">
                <label className="form-label">Nombre del Evento:</label>
                <input
                  type="text"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                  placeholder="Ingresa el nombre del evento"
                  className="input-field"
                />
              </div>
  
              <div className="form-group">
                <label className="form-label">Contraseña del Evento:</label>
                <input
                  type="password"
                  value={eventPassword}
                  onChange={(e) => setEventPassword(e.target.value)}
                  placeholder="Ingresa la contraseña del evento"
                  className="input-field"
                />
              </div>
  
              {/* Mostrar mensaje de error si lo hay */}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
  
              <button onClick={validateEvent} className="login-btn">Validar Evento</button>
            </>
          ) : (
            <>
              {/* Paso 2: Registro de usuario */}
              <h2 className="login-title">Ingresa tus datos</h2>
  
              <div className="photo-preview-container">
                {photo ? (
                  <img src={URL.createObjectURL(photo)} alt="Vista previa" className="photo-preview" />
                ) : (
                  <div className="photo-placeholder">Sin Foto</div>
                )}
              </div>
  
              <div className="form-group">
                <label className="form-label">Nombre:</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Tu nombre"
                  className="input-field"
                />
              </div>
  
              <div className="form-group">
                <label className="form-label">Edad:</label>
                <input
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  placeholder="Tu edad"
                  className="input-field"
                />
              </div>
  
              <div className="form-group">
                <label className="form-label">Género:</label>
                <select 
                  value={gender} 
                  onChange={(e) => setGender(e.target.value)} 
                  className="input-field"
                >
                  <option value="chico">Chico</option>
                  <option value="chica">Chica</option>
                  <option value="otro">Otro</option>
                </select>
              </div>
  
              <div className="form-group">
                <label className="form-label">Orientación Sexual:</label>
                <select 
                  value={orientation} 
                  onChange={(e) => setOrientation(e.target.value)} 
                  className="input-field"
                >
                  <option value="heterosexual">Heterosexual</option>
                  <option value="homosexual">Homosexual</option>
                  <option value="bisexual">Bisexual</option>
                </select>
              </div>
  
              <div className="form-group">
                <label className="form-label">Foto:</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  className="input-field"
                />
              </div>
  
              {/* Mostrar mensaje de error si lo hay */}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
  
              <button onClick={handleSubmit} className="login-btn">Empezar Cuestionario</button>
            </>
          )}
        </>
      )}
    </div>
  );
  
}

export default LoginPage;
