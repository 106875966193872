import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig';
import { useLocation } from 'react-router-dom';

const AdminPanel = () => {
  const [evento, setEvento] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get('token');

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await fetch(`/api/admin-panel/${token}`);
        const data = await response.json();
        
        if (response.ok && data.evento) {
          setEvento(data.evento);

          // Escuchar cambios en la subcolección de 'participants' para el evento correcto
          db.collection('eventos')
            .where('adminToken', '==', token) // Usa el token para identificar el evento
            .get()
            .then((querySnapshot) => {
              if (!querySnapshot.empty) {
                const eventDoc = querySnapshot.docs[0]; // Supone que hay un solo evento con el token
                setEvento({ id: eventDoc.id, ...eventDoc.data() });

                // Suscribirse a los cambios en la subcolección 'participants'
                db.collection('eventos')
                  .doc(eventDoc.id)
                  .collection('participants')
                  .onSnapshot((snapshot) => {
                    const participantsList = snapshot.docs.map(doc => doc.data());
                    setParticipants(participantsList);
                  });
              } else {
                setErrorMessage('Evento no encontrado.');
              }
            })
            .catch((error) => {
              console.error('Error al obtener el evento:', error);
              setErrorMessage('Error al obtener el evento');
            });
        } else {
          setErrorMessage(data.message || 'Acceso denegado');
        }
      } catch (error) {
        console.error('Error al validar el token:', error);
        setErrorMessage('Error al validar el token');
      } finally {
        setLoading(false);
      }
    };

    validateToken();
  }, [token]);

  const startQuiz = () => {
    db.collection('eventos')
      .doc(evento.id) // Usa el ID del evento para actualizar el documento correcto
      .update({ quizStarted: true })
      .then(() => {
        console.log('Cuestionario iniciado');
      })
      .catch(error => {
        console.error('Error al iniciar el cuestionario:', error);
      });
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <div>
      <h2>Panel de Control para el Evento: {evento.nombre_evento}</h2>
      <h4>Fecha del Evento: {new Date(evento.fecha_evento._seconds * 1000).toLocaleDateString()}</h4>

      <div>
        <h3>Participantes</h3>
        {participants.length > 0 ? (
          <ul>
            {participants.map((participant, index) => (
              <li key={index}>{participant.nombre_usuario || `Participante ${index + 1}`}</li>
            ))}
          </ul>
        ) : (
          <p>Aún no hay participantes en el evento.</p>
        )}
      </div>

      <button onClick={startQuiz} disabled={participants.length === 0}>
        Iniciar Cuestionario
      </button>
    </div>
  );
};

export default AdminPanel;
