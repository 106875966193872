import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowDown, FaQuestionCircle, FaLock, FaUserShield, FaBeer } from 'react-icons/fa';
import './Home.css';
import betaImage from './beta.png';

function Home() {
  const navigate = useNavigate();

  const handleScrollDown = () => {
    const infoSection = document.getElementById('info-section');
    if (infoSection) {
      infoSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className="home-container">
        <div className="main-content">
          <img src={betaImage} alt="Beta Version" className="beta-badge" />
          <h1 className="big-heading">PUB TO</h1>
          <h1 className="soul-heading">MATCH</h1>
          <div className="buttons-section">
            <button onClick={() => navigate('/first-form')} className="main-btn">Comenzar</button>
            <button onClick={() => navigate('/login')} className="secondary-btn">Tengo un código</button>
          </div>
          <div className="scroll-indicator" onClick={handleScrollDown}>
            <FaArrowDown />
          </div>
        </div>
      </div>

      <div id="info-section" className="info-section">
        <div className="faq-grid">
          <div className="faq-item">
            <FaQuestionCircle className="faq-icon" />
            <h4>¿Cómo funcionaaaaaaaaaaaaaa la aplicación?</h4>
            <p>PubToMatch permite crear eventos seleccionando tu mismo las preguntas para emparejarse con personas que comparten intereses similares.</p>
          </div>

          <div className="faq-item">
            <FaQuestionCircle className="faq-icon" />
            <h4>¿Cómo se hacen los matches?</h4>
            <p>Hemos creado un algoritmo que en base a los resultados y a la ciencia de las relaciones se realizan los matches.</p>
          </div>

          <div className="faq-item">
            <FaQuestionCircle className="faq-icon" />
            <h4>¿Cómo puedo comenzar?</h4>
            <p>Simplemente haz clic en "Comenzar" y sigue los pasos para crear tu propio evento, configurandolo a tu medida.</p>
          </div>

          <div className="faq-item">
            <FaUserShield className="faq-icon" />
            <h4>¿Me han invitado al evento, que hago?</h4>
            <p>Con el nombre del evento y el código que te proporcione el dueño que ha creado el evento, podrás acceder al evento privado, accediendo a <b>Tengo un código</b></p>
          </div>

          <div className="faq-item">
            <FaLock className="faq-icon" />
            <h4>¿Puede entrar gente que no está invitada?</h4>
            <p>No, ya que son eventos privados y necesitan las credenciales para entrar, así aseguramos a nuestros clientes que la gente que participe, está físicamente en el lugar.</p>
          </div>

          <div className="faq-item">
            <FaLock className="faq-icon" />
            <h4>¿Es segura la aplicación?</h4>
            <p>La seguridad de nuestros usuarios es nuestra máxima prioridad. Utilizamos encriptación de extremo a extremo en todas las comunicaciones.</p>
          </div>

          <div className="faq-item">
            <FaBeer className="faq-icon" />
            <h4>¿Cuáles son los beneficios de usar esta aplicación?</h4>
            <p>Nuestra aplicación te permite conocer gente nueva en un ambiente relajado y divertido. Además, puedes descubrir bares y pubs locales que quizás no conocías.</p>
          </div>

          <div className="faq-item">
            <FaQuestionCircle className="faq-icon" />
            <h4>¿Dónde puedo utilizar la aplicación?</h4>
            <p>Puedes utilizarla en cualquier lugar... En un pub, en casa con tus amigos, eventos, quedadas, donde tú decidas!</p>
          </div>
        </div>

        <div className="faq-item-2">
          <FaQuestionCircle className="faq-icon" />
          <h4>¿Es gratuita la aplicación?</h4>
          <p>Sí, la aplicación es totalmente gratuita ya que se encuentra en fase beta.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
