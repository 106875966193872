import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './QuestionsSelection.css'; // Asegúrate de vincular el archivo CSS

function QuestionsSelection() {
  const allCategories = {
    Cotidianas: [
      "Me gusta pasat tiempo al aire libre",
      "Me gusta leer",
      "Me ha llamado la atención alguien",
      "Me gusta planificar todo",
      "Me considero una persona organizada",
      "Me gustan las conversaciones profundas",
      "Soy optimista",
      "Me adapto fácilmente a los cambios",
      "Me gusta bailar",
      "Soy madrugador/a",
      "Me gustan los animales",
      "Me gustan los videojuegos",
      "Hago deporte",
      "Festivales de música",
      "Me gusta hacer karaoke",
      "Me gusta el humor políticamente incorrecto",
      "Vivirías una vida feliz en soledad",
      "Ser amigo/a de tu ex",
      "Me levanto con mi primera alarma",
      "Me gustaría tener hijos",
      "Probaría cualquier droga al menos una vez",
      "Creo en fantasmas",
      "Creo en los extraterrestres",
      "Es importante para ti la honestidad",
      "Desconfío de la gente por defecto",
      "Te gusta la comida basura",
      "¿Cambiarías tu nombre si pudieras?",
    ],
    Aventuras: [
      "¿Te gusta viajar?",
      "Viajaría solo alguna vez",
      "Planifico todos los viajes",
      "¿Estarías dispuesta/o a coger e irte mañana de viaje improvisado?",
      "Me encanta la naturaleza para pasar mis vacaciones y desconectar",
      "Me gusta estar en cualquier ciudad de fiesta pasando mis vacaciones",
      "Me gusta vivir aventuras e improvisar allá donde vaya",
    ],
    Románticas: [
      "¿Te consideras una persona romántica?",
      "¿Te gusta planificar citas sorpresa?",
      "¿Qué tan importante es la comunicación en una relación?",
      "¿Crees en el amor a primera vista?",
      "¿Te gustan las cartas de amor?",
      "¿Qué tan importante es el humor en una relación?",
      "¿Disfrutas de los paseos románticos bajo la luna?",
      "¿Prefieres tener una relación estable o algo más espontáneo?",
      "¿Te gusta que te den sorpresas?",
      "Es importante para mí que mis padres aprueben mi pareja",
      "¿Invitarías o te dejarías invitar en la primera cita?",
    ],
    Picantes: [
      "¿Te gustaría experimentar un trío?",
      "¿El sexo con un toque perverso te atrae?",
      "¿Has experimentado con juguetes sexuales?",
      "¿Te gusta hablar sucio durante el sexo?",
      "¿Te considerarías una persona con mente abierta en la cama?",
      "¿Te gustaría probar el sexo en lugares públicos?",
      "¿Qué tan importante es el juego previo para ti?",
      "¿Cuánto te atrae la idea de intercambiar de pareja?",
      "Me gusta más el sexo romántico que el salvaje",
      "¿Disfrutas de tomar el control durante el sexo?",
      "¿Te gustaría que tu pareja te atara durante el sexo?",
    ]
  };

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const navigate = useNavigate();

  const handleSelect = (question) => {
    if (!selectedQuestions.includes(question)) {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };

  const handleDeselect = (question) => {
    setSelectedQuestions(selectedQuestions.filter((q) => q !== question));
  };

  const handleSaveAndNext = () => {
    if (selectedQuestions.length === 0) {
      alert('Debes seleccionar al menos una pregunta.');
      return;
    }
  
    const fecha_evento = '2024-10-01';  // Aquí defines la fecha del evento, puede ser dinámica según tu aplicación
    
    // Guarda las preguntas seleccionadas y la fecha en el localStorage
    localStorage.setItem('selectedQuestions', JSON.stringify(selectedQuestions));
    localStorage.setItem('fecha_evento', fecha_evento);  // Guarda la fecha del 
    
    // Depurar: Verificar que las preguntas se guardan correctamente en localStorage
    console.log('Preguntas guardadas en localStorage:', selectedQuestions);
    console.log('Fecha guardada en localStorage:', fecha_evento);
    
    navigate('/price');
  };

  return (
    <div className="questions-selection-container">
      {/* Navigation Bar */}
      <nav className="nav-bar">
        <h2 className="logo" onClick={() => navigate('/')}>PubToMatch</h2>
        {/* <ul className="nav-links">
          <li onClick={() => navigate('/')}>Work.</li>
          <li onClick={() => navigate('/')}>About.</li>
          <li onClick={() => navigate('/')}>Culture.</li>
          <li onClick={() => navigate('/')}>Latest.</li>
          <li onClick={() => navigate('/')}>Contact.</li>
        </ul> */}
      </nav>

      <h2 className="section-title">Seleccionar preguntas para el evento</h2>

      <div className="questions-wrapper">
        <div className="questions-list">
          <h3 className="subheading">Preguntas disponibles</h3>
          {Object.keys(allCategories).map((category) => (
            <details key={category} className="category">
              <summary>{category}</summary>
              <ul className="question-list">
                {allCategories[category]
                  .filter((q) => !selectedQuestions.includes(q))
                  .map((question) => (
                    <li key={question} onClick={() => handleSelect(question)} className="question-item">
                      {question}
                    </li>
                  ))}
              </ul>
            </details>
          ))}
        </div>

        <div className="selected-questions">
          <h3 className="subheading">Preguntas seleccionadas</h3>
          <ul className="question-list">
            {selectedQuestions.map((question, index) => (
              <li key={index} onClick={() => handleDeselect(question)} className="question-item">
                {question}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="button-container">
        <button onClick={() => navigate(-1)} className="back-btn">Atrás</button>
        <button onClick={handleSaveAndNext} className="next-btn">Siguiente</button>
      </div>
    </div>
  );
}

export default QuestionsSelection;
