import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FirstForm.css'; // Vincula el archivo CSS

function FirstForm() {
  const [nombreEvento, setNombreEvento] = useState('');
  const [fechaEvento, setFechaEvento] = useState('');
  const [tipoEmparejamiento, setTipoEmparejamiento] = useState('grupo');
  const [lugarEvento, setLugarEvento] = useState('Bar');
  const [experienciaJuego, setExperienciaJuego] = useState('heterosexual');
  
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      nombre_evento: nombreEvento,
      fecha_evento: fechaEvento,
      tipo_emparejamiento: tipoEmparejamiento,
      lugar_evento: lugarEvento,
      experiencia_juego: experienciaJuego,
    };

    localStorage.setItem('formData', JSON.stringify(formData));
    localStorage.setItem('nombreEvento', nombreEvento);
    navigate('/questions-selection');
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="first-form-container">
      <nav className="nav-bar">
        <h2 className="logo" onClick={() => navigate('/')}>PubToMatch</h2>
        {/* <ul className="nav-links">
          <li onClick={() => navigate('/')}>Work.</li>
          <li onClick={() => navigate('/')}>About.</li>
          <li onClick={() => navigate('/')}>Culture.</li>
          <li onClick={() => navigate('/')}>Latest.</li>
          <li onClick={() => navigate('/')}>Contact.</li>
        </ul> */}
      </nav>

      <h1 className="heading">Crear un Nuevo Evento</h1>

      {/* Main Content */}
      <div className="content">
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label>Nombre del evento</label>
            <input
              type="text"
              value={nombreEvento}
              onChange={(e) => setNombreEvento(e.target.value)}
              required
              className="input-large"
            />
          </div>

          <div className="form-group">
            <label>Tipo de invitados</label>
            <div className="radio-group">
              <div>
                <input
                  type="radio"
                  value="grupo"
                  checked={tipoEmparejamiento === 'grupo'}
                  onChange={() => setTipoEmparejamiento('grupo')}
                /> Grupo de amigos
              </div>
              <div>
                <input
                  type="radio"
                  value="clientes"
                  checked={tipoEmparejamiento === 'clientes'}
                  onChange={() => setTipoEmparejamiento('clientes')}
                /> Clientes
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Lugar del evento</label>
            <div className="radio-group">
              <div>
                <input
                  type="radio"
                  value="Bar"
                  checked={lugarEvento === 'Bar'}
                  onChange={() => setLugarEvento('Bar')}
                /> Bar
              </div>
              <div>
                <input
                  type="radio"
                  value="Evento"
                  checked={lugarEvento === 'Evento'}
                  onChange={() => setLugarEvento('Evento')}
                /> Evento
              </div>
              <div>
                <input
                  type="radio"
                  value="Reunion"
                  checked={lugarEvento === 'Reunion'}
                  onChange={() => setLugarEvento('Reunion')}
                /> Reunión
              </div>
              <div>
                <input
                  type="radio"
                  value="Casa"
                  checked={lugarEvento === 'Casa'}
                  onChange={() => setLugarEvento('Casa')}
                /> Casa
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Experiencia de juego</label>
            <div className="radio-group">
              <div>
                <input
                  type="radio"
                  value="heterosexual"
                  checked={experienciaJuego === 'heterosexual'}
                  onChange={() => setExperienciaJuego('heterosexual')}
                /> Heterosexual
              </div>
              <div>
                <input
                  type="radio"
                  value="mismo_sexo"
                  checked={experienciaJuego === 'mismo_sexo'}
                  onChange={() => setExperienciaJuego('mismo_sexo')}
                /> Mismo sexo
              </div>
              <div>
                <input
                  type="radio"
                  value="bisexual"
                  checked={experienciaJuego === 'bisexual'}
                  onChange={() => setExperienciaJuego('bisexual')}
                /> Bisexual
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Fecha del evento:</label>
            <input
              type="date"
              value={fechaEvento}
              onChange={(e) => setFechaEvento(e.target.value)}
              min={today}
              required
              className="input-large"
            />
          </div>

          <div className="button-group">
            <button onClick={() => navigate(-1)} className="back-btn">Atrás</button>
            <button type="submit" className="next-btn">Siguiente</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FirstForm;
