import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PricePage.css'; // Asegúrate de vincular el archivo CSS

function PricePage() {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [price, setPrice] = useState(0);
  const [numInvitados, setNumInvitados] = useState(0);  
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [cobrarEntrada, setCobrarEntrada] = useState('no');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [nombreEvento, setNombreEvento] = useState('');
  const [fechaEvento, setFechaEvento] = useState(''); // Nueva variable de estado para la fecha del evento

  useEffect(() => {
    const questions = JSON.parse(localStorage.getItem('selectedQuestions')) || [];
    setSelectedQuestions(questions);
  
    const nombre = localStorage.getItem('nombreEvento') || '';
    setNombreEvento(nombre);
  
    const fecha = localStorage.getItem('fecha_evento') || '2024-10-01';  // Recuperar la fecha del evento de localStorage
    setFechaEvento(fecha);  // Asegúrate de que la fecha se está configurando correctamente
  }, []);

  useEffect(() => {
    setPrice(numInvitados * 2);
  }, [numInvitados]);

  const handleCreateEvent = async () => {
    if (!phoneNumber || !email || !password || !nombreEvento || !fechaEvento) {
      alert('Por favor, completa todos los campos requeridos.');
      return;
    }
  
    localStorage.setItem('nombreEvento', nombreEvento);
    localStorage.setItem('fecha_evento', fechaEvento);
  
    const data = {
      email,
      phoneNumber,
      password,
      selectedQuestions,
      price,
      numInvitados,
      nombre_evento: nombreEvento,
      fecha_evento: fechaEvento,
    };
  
    console.log('Datos enviados al servidor:', data);
  
    try {
      const response = await fetch('https://pubtomatch.com/api/send-confirmation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      });
  
      const responseData = await response.text();
      console.log('Respuesta del servidor:', responseData);
  
      if (response.ok) {
        alert('Operación exitosa. Se ha enviado un correo con el enlace de monitoreo.');
      } else {
        alert('Error al enviar el correo.');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      alert('Hubo un error al confirmar la operación.');
    }
  };
  
  

  return (
    <div className="price-page-container-ulti">
      {/* Navigation Bar */}
      <nav className="nav-bar">
        <h2 className="logo" onClick={() => navigate('/')}>PubToMatch</h2>
      </nav>

      {/* Main Content */}
      <div className="content">
        <h2 className="heading">Resumen del evento</h2>
        <h3>Preguntas seleccionadas:</h3>
        <ul>
          {selectedQuestions.map((question, index) => (
            <li key={index}>{question}</li>
          ))}
        </ul>

        <div className="form-group">
          <label>Número de invitados:</label>
          <input
            type="number"
            value={numInvitados}
            onChange={(e) => setNumInvitados(Number(e.target.value))}
            placeholder="Número de invitados"
          />
        </div>

        <h3>Precio total: {price} €</h3>

        <h2>¿Cobrar por las entradas a los invitados?</h2>
        <div className="entrada-options">
          <div onClick={() => setCobrarEntrada('no')}>
            <p>No - Cubriré el costo yo mismo.</p>
          </div>
          <div onClick={() => setCobrarEntrada('si')}>
            <p>Sí - Incluiré el costo en las entradas.</p>
          </div>
          {cobrarEntrada === 'si' && (
            <p>Costo por persona: {numInvitados > 0 ? (price / numInvitados).toFixed(2) : 0} €</p>
          )}
        </div>

        <div className="form-group">
          <label>Número de teléfono:</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Ingresa tu número de teléfono"
          />
        </div>

        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ingresa tu correo electrónico"
          />
        </div>

        <div className="form-group">
          <label>Contraseña para la sala privada:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Crea una contraseña para la sala privada"
          />
        </div>

        <div className="button-group">
          <button onClick={() => navigate(-1)} className="back-btn">Atrás</button>
          <button onClick={handleCreateEvent} className="next-btn">Confirmar y Pagar</button>
        </div>
      </div>

      <div className="instructions">
        <p><strong>Instrucciones:</strong></p>
        <p>El nombre de acceso para tus invitados será <strong>{nombreEvento}</strong> y la contraseña que has creado será <strong>{password || '________'}</strong>.</p>
      </div>
    </div>
  );
}

export default PricePage;
